import React from 'react';
import Lightbox from 'react-image-lightbox';
import Gallery from 'react-photo-gallery';
import 'react-image-lightbox/style.css';

function getImageUrl(imageInfo, idx) {
    return imageInfo[idx].imageinfo[0].url;
}

export function DetailLightbox(props) {
    const { imageInfo, currentImage, setCurrentImage, onClose } = props;
    if (!imageInfo || imageInfo.length === 0) {
        return null;
    }
    const nImages = imageInfo.length;

    const nextIndex = (currentImage + 1) % nImages;
    const prevIndex = currentImage === 0 ? nImages - 1 : currentImage - 1;

    const prevImage = () => {
        setCurrentImage(prevIndex);
    }

    const nextImage = () => {
        setCurrentImage(nextIndex);
    }

    return (
            <Lightbox
                imagePadding={80}
                wrapperClassName="record-detail-lightbox"
                mainSrc={getImageUrl(imageInfo, currentImage)}
                nextSrc={getImageUrl(imageInfo, nextIndex)}
                prevSrc={getImageUrl(imageInfo, prevIndex)}

                onMovePrevRequest={prevImage}
                onMoveNextRequest={nextImage}
                images={imageInfo.map((x, i) => getImageUrl(imageInfo, i))}
                onCloseRequest={onClose} />

    );
}

export function DetailGallery(props) {
    const { onClick, imageInfo } = props;
    if (!imageInfo || imageInfo.length === 0) { return null };

    const galleryInfo = imageInfo.map((x) => {
        const ii = x.imageinfo[0];
        return {
            width: ii.thumbwidth,
            height: ii.thumbheight,
            src: ii.thumburl
        }
    });

    return (
        <Gallery
            onClick={onClick}
            targetRowHeight={128}
            photos={galleryInfo} />
    );
}
