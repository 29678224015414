
const wikidataFields = [
    {
        id: 'fma',
        label: 'FMA',
        property: 'P1402',
        url: (x) => 'http://fma.si.washington.edu/browser/#/?iri=http%3A%2F%2Fpurl.org%2Fsig%2Font%2Ffma%2Ffma' + x
    },
    {
        id: 'mesh',
        label: "MeSH",
        property: "P486",
        url: (x) => 'https://meshb.nlm.nih.gov/record/ui?ui=' + x
    },
    {
        id: 'neuronames',
        label: "NeuroNames",
        property: "P4394",
        url: (x) => 'http://braininfo.rprc.washington.edu/centraldirectory.aspx?ID=' + x
    },
    {
        id: 'uberon',
        label: "UBERON",
        property: 'P1554',
        url: (x) => 'http://purl.obolibrary.org/obo/UBERON_' + x
    },
    {
        id: 'umls',
        label: "UMLS",
        property: "P2892",
        url: (x) => 'https://ncim-stage.nci.nih.gov/ncimbrowser/ConceptReport.jsp?dictionary=NCI%20Metathesaurus&code=' + x
    }
]

export default wikidataFields;
