import React from 'react';
import { ReactComponent as Logo } from './img/ta2-terminologia.svg';
import ta2Version from './ta2version';
import { Trans } from 'react-i18next';

export default function LogoSplash(props) {
    return (
        <div className="record-splash">
            <Logo style={{
                "marginLeft": "auto",
                "marginRight": "auto",
                display: "block"
            }}
            />
            <div className="record-splash-text">
                <Trans i18nKey="splash_text" >
                    <h1>TA version {{ ta2Version_vocab: ta2Version.vocab }}</h1>
                    <p>Terminologia Anatomica is the international standard of anatomical
                        terminology published by &nbsp;
                        <a href="https://fipat.library.dal.ca/" target="_blank" rel="noopener noreferrer">
                            FIPAT (Federative International Programme for Anatomical Terminology)</a>, a program of IFAA
                        (the International Federation of Associations of Anatomists).</p>
                    <p>For more information on TA2, including status, citation and licensing information,
                        please see: <a href="https://fipat.library.dal.ca/ta2/" target="_blank" rel="noopener noreferrer">
                            https://fipat.library.dal.ca/ta2/</a>.</p>

                    <p>TA2Viewer is a joint project between the IFAA and the&nbsp;
                        <a href="https://openanatomy.org" target="_blank" rel="noopener noreferrer">Open Anatomy Project</a>
                        &nbsp;
                        at the Brigham and Women’s Hospital in Boston. Please direct questions
                        about TA2Viewer to info@openanatomy.org.
                    </p>
                    <p>
                        TA2Viewer has received the endorsement
                        of the &nbsp;
                            <a href="https://clinical-anatomy.org/" target="_blank" rel="noopener noreferrer">
                                American Association of Clinical Anatomists (AACA)</a>&nbsp; 
                                and the &nbsp;
                            <a href="https://www.apa-paa.com" target="_blank" rel="noopener noreferrer">
                                Pan-American Association of Anatomy (APA-PAA)</a>.
                    </p>
                </Trans>
            </div>
        </div>);
}
