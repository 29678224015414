import React, { useRef, useState, useEffect } from 'react';
import SplitPane from 'react-split-pane';
import { createBrowserHistory } from 'history';
import queryString from 'query-string';
import Select from 'antd/lib/select';

import { useLocalStorage } from 'react-use';

import { RecordSearchView } from "./RecordSearchView";
import { RecordTreeView } from "./RecordTreeView";
import { RecordDetailView } from './RecordDetailView';
import MediaQuery from 'react-responsive';
import Icon from 'antd/lib/icon';
import Button from 'antd/lib/button';
import Badge from 'antd/lib/badge';
import { useTranslation } from "react-i18next";
import languageDetect from './languageDetect';

import './App.css';
import { Settings } from './Settings';
const Option = Select.Option;

const DefaultTreeWidth = 350;

function TermLanguageSelect(props) {
  const { t } = useTranslation();
  return (<Select
    defaultValue="en_US"
    {...props}>
    <Option value="la">{t('latin')}</Option>
    <Option value="en_GB">{t('uk_english')}</Option>
    <Option value="en_US">{t('us_english')}</Option>
  </Select>
  )
}

// const SupportedTermLanguages = ['la', 'en_GB', 'en_US'];
const DefaultTermLanguage = 'la';
const DefaultInterfaceLanguage = 'en';
const DefaultWikidataSynonymsEnabled = false;

function App(props) {
  const { t, i18n } = useTranslation();
  const [searchString, setSearchString] = useState('');
  const [selectedIds, setSelectedIds] = useState([]);
  const [expandedIds, setExpandedIds] = useState([]);
  const [settingsVisible, setSettingsVisible] = useState(false);

  const [termLanguage, setTermLanguage] = 
    useLocalStorage('termLanguage', DefaultTermLanguage);
  const [interfaceLanguage, setInterfaceLanguage] = 
    useLocalStorage('interfaceLanguage', DefaultInterfaceLanguage);
  const [useBrowserLanguage, setUseBrowserLanguage] = 
    useLocalStorage('useBrowserLanguage', true);
  const [wikidataSynonymsEnabled, setWikidataSynonymsEnabled] = 
    useLocalStorage('wikidataSynonymsEnabled', DefaultWikidataSynonymsEnabled);

  const historyRef = useRef();
  const updateAvailable = false;

  useEffect(() => {
    const ld = languageDetect();
    if(useBrowserLanguage) {
      if(ld) {
        i18n.changeLanguage(ld[0]);
      }
    }
    else {
      i18n.changeLanguage(interfaceLanguage);
    }
  }, [useBrowserLanguage, interfaceLanguage, i18n]);

  useEffect(() => {
    const history = createBrowserHistory({
      basename: new URL(document.URL).pathname
    }
    );

    const handleHistory = (location, action) => {
      if (action === 'POP' || action === 'X-INIT') {
        let selectedIds_ = [];
        let expandedIds_ = [];
        let searchString_ = '';

        const query = queryString.parse(location.search);
        if (query.id && props.recordTree.isValidId(query.id)) {
          selectedIds_ = [query.id];
        }

        if (selectedIds_.length === 1) {
          expandedIds_ = props.recordTree.getAncestorIds(selectedIds_[0]);
        }
        setSelectedIds(selectedIds_);
        setExpandedIds(expandedIds_);
        setSearchString(searchString_);
      }
    }

    const unlisten = history.listen(handleHistory);
    handleHistory(history.location, 'X-INIT');
    historyRef.current = history;
    return unlisten;
  }, [props.recordTree]);

  const historyMatches = (id) => {
    if (id === null && selectedIds && selectedIds.length > 0) {
      return false;
    }
    if (id !== null && selectedIds && selectedIds.length === 0) {
      return false;
    }
    if (id !== null && id !== selectedIds[0]) {
      return false;
    }
    return true;
  }

  const pushHistory = (id) => {
    let uri = '/';
    let q = [];

    if (historyMatches(id)) {
      return;
    }
    if (id) {
      q = [`id=${id}`];
    }
    if (q.length) {
      uri = '/?' + q.join('&');
    }
    historyRef.current.push(uri);
  }

  const onSearchSelect = (selectedIds_) => {
    if (selectedIds_.length === 1) {
      const expandedIds_ = props.recordTree.getAncestorIds(selectedIds_[0]);
      setSelectedIds(selectedIds_);
      setExpandedIds(expandedIds_);
      setSearchString('');
      pushHistory(selectedIds_[0]);
    }
  }

  const onSelect = (selectedIds_, info) => {
    if (selectedIds_.length === 1) {
      setSearchString('');
      const expandedIds_ = props.recordTree.getAncestorIds(selectedIds_[0]);
      setExpandedIds(expandedIds_);
    }
    pushHistory(selectedIds_[0]);
    setSelectedIds(selectedIds_);
  }

  const reset = () => {
    setSelectedIds([]);
    setExpandedIds([]);
    setSearchString('');
    pushHistory(null);
  }

  const onSearch = (searchString_) => {
    setSearchString(searchString_);
  }

  return (
    <MediaQuery minDeviceWidth={660}>
      {(matches) => matches ? renderDesktop() : renderMobile()}
    </MediaQuery>
  );

  function renderDesktop() {
    return (
      <div className="record-viewer">
        <SplitPane split="horizontal" minSize={60} maxSize={60}>
          <header>
            <div className="record-title">
              <h1 onClick={reset}>TA2 Viewer</h1>
            </div>
            <RecordSearchView className="record-search"
              recordSearch={props.recordSearch}
              recordTree={props.recordTree}
              onSearch={onSearch}
              onSelect={onSearchSelect}
              searchString={searchString}
              placeholder={t('search')}
              termLanguage={termLanguage}
              wikidataSynonymsEnabled={wikidataSynonymsEnabled}
            />
            <div className="record-buttonbar">
              <TermLanguageSelect
                className="record-select"
                onChange={setTermLanguage}
                value={termLanguage}
              />
              <Button.Group className="buttons">
                <Button className="settings" onClick={() => setSettingsVisible(true)}>                
                <Badge dot={updateAvailable}>
                  <Icon type="setting"
                  style={{ fontSize: "18px" }} />
                </Badge>

                  </Button>
                <Button className="about" onClick={reset}><Icon type="question-circle"
                  style={{ fontSize: "18px" }} /></Button>
              </Button.Group>
            </div>
            <Settings 
              visible={settingsVisible} 
              dismiss={() => setSettingsVisible(false)}
              interfaceLanguage={interfaceLanguage}
              setInterfaceLanguage={setInterfaceLanguage}
              useBrowserLanguage={useBrowserLanguage}
              setUseBrowserLanguage={setUseBrowserLanguage}
              wikidataSynonymsEnabled={wikidataSynonymsEnabled}
              setWikidataSynonymsEnabled={setWikidataSynonymsEnabled}
              />
          </header>
          <div>
            <SplitPane split="vertical" minSize={0} defaultSize={DefaultTreeWidth}>
              <div style={{ width: "100%", height: "100%", overflow: "auto" }}>

                <RecordTreeView
                  className="record-tree"
                  recordTree={props.recordTree}
                  onSelect={onSelect}
                  onExpand={setExpandedIds}
                  expandedIds={expandedIds}
                  selectedIds={selectedIds}
                  language={termLanguage}
                />
              </div>
              <div style={{ height: "100%", overflow: "auto" }}>
                <RecordDetailView
                  className="record-detail"
                  selectedIds={selectedIds}
                  recordTree={props.recordTree}
                  onSelect={onSelect}
                  language={termLanguage}
                />
              </div>
            </SplitPane>
          </div>
        </SplitPane>
      </div>
    );
  }


  function renderMobile() {
    return (
      <div className="record-viewer">
        <header className="record-header">
          <div className="record-title">
            <h1 onClick={reset}>TA2 Viewer</h1>
          </div>
          <RecordSearchView className="record-search"
              recordSearch={props.recordSearch}
              recordTree={props.recordTree}
              onSearch={onSearch}
              onSelect={onSearchSelect}
              searchString={searchString}
              placeholder={t('search')}
              termLanguage={termLanguage}
              wikidataSynonymsEnabled={wikidataSynonymsEnabled}
            />
          <div className="record-buttonbar">

            <TermLanguageSelect
              className="record-select"
              onChange={setTermLanguage}
              value={termLanguage}
            />
            <Button.Group className="buttons">
              <Button className="settings" onClick={setSettingsVisible}><Icon type="setting"
                style={{ fontSize: "18px" }} /></Button>
              <Button className="about" onClick={reset}><Icon type="question-circle"
                style={{ fontSize: "18px" }} /></Button>
            </Button.Group>
            <Settings 
              visible={settingsVisible} 
              dismiss={() => setSettingsVisible(false)}
              interfaceLanguage={interfaceLanguage}
              setInterfaceLanguage={setInterfaceLanguage}
              useBrowserLanguage={useBrowserLanguage}
              setUseBrowserLanguage={setUseBrowserLanguage}
              wikidataSynonymsEnabled={wikidataSynonymsEnabled}
              setWikidataSynonymsEnabled={setWikidataSynonymsEnabled}
              />
          </div>
        </header>
        <div className="record-main">
          <RecordDetailView
            className="record-detail"
            selectedIds={selectedIds}
            recordTree={props.recordTree}
            onSelect={onSelect}
            language={termLanguage}
          />
          <RecordTreeView
            className="record-tree"
            recordTree={props.recordTree}
            onSelect={onSelect}
            onExpand={setExpandedIds}
            expandedIds={expandedIds}
            selectedIds={selectedIds}
            language={termLanguage}
          />
        </div>
      </div>
    );

  }
}

export default App;
