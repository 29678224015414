import React from 'react';
import Tree from 'antd/lib/tree';

export const TreeNode = Tree.TreeNode;

export function RecordTreeView(props) {
    const { recordTree } = props;

    return (<div className={props.className}>
        <Tree 
            showLine
            onSelect={props.onSelect} 
            expandedKeys={props.expandedIds} 
            autoExpandParent={false} 
            onExpand={props.onExpand} 
            selectedKeys={props.selectedIds}
            >
            {recordTree.rootIds.map(m => renderTreeNodesFromChildren(recordTree, m, props.language))}
        </Tree>
    </div>);
}
function renderTreeNodesFromChildren(ta, id, lang) {
    const childrenIds = ta.getChildrenIds(id);
    let styleClass;
    styleClass = `record-tree-item level-${ta.lookup(id).level}`;
    // styleClass = 'record-tree-item';
    return (<TreeNode title={<span className={styleClass}>{ta.getTerm(id, lang)}</span>} key={id}>
        {childrenIds.map(c => renderTreeNodesFromChildren(ta, c, lang))}
    </TreeNode>);
}
