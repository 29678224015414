const languages = {
    en: 'English',
    // af: 'Afrikaans',
    ar: 'العربية',
    de: 'Deutsch',
    es: 'Español',
    fr: 'Français',
    hi: 'हिन्दी',
    id: 'Bahasa Indonesia',
    it: 'Italiano',
    ja: '日本語',
    ne: 'नेपाली',
    no: 'Norsk',
    pt: 'Português',
    ru: 'Русский язык',
    sv: 'Svenska',
    tr: 'Türkçe',
    zh: '普通话',
};

const languageOrder = [
    'en',
    // 'af',
    'ar',
    'de',
    'es',
    'fr',
    'hi',
    'id',
    'it',
    'ja',
    'ne',
    'no',
    'pt',
    'ru',
    'sv',
    'tr',
    'zh'
];

const translations = {
    en: {
        translation: {
            search_options: 'Search options',
            use_wikidata_terms: "Use Wikidata terms",
            interface_language: 'Interface language',
            browser_default: 'Browser default',
            settings_header: 'Settings',
            see_also: 'See also',
            english_synonyms: 'English synonyms',
            latin_synonyms: 'Latin synonyms',
            primary_usage: 'Primary usage',
            secondary_usage: 'Secondary usage',
            sex: "Sex",
            inconstant: 'Inconstant',
            yes: "Yes",
            related_terms: "Related terms",
            synonyms: "Synonyms",
            search: 'search',
            external_search: 'External search',
            external_search_links: 'External search links',
            external_search_links_body: `
These links allow quick term searches of medical resources and search engines. Results are not reviewed or curated by FIPAT.`,
            unofficial_xrefs: 'Unofficial cross-references',
            unofficial_xrefs_body: `
Cross-references are provided by Wikidata. They are contributed by the Wikipedia community and are not reviewed or curated by FIPAT.`,
            latin: 'Latin',
            latin_term: 'Latin term',
            english_term: 'English term',
            uk_english: 'UK English',
            us_english: 'US English',
            term_number: 'Term number',
            children: 'Children',
            notes: 'Notes',
            grays_anatomy: "Gray's Anatomy (20th ed.)",
            wikipedia_sites: 'Wikipedia sites',
            wikidata_translations: 'Wikidata translations',
            wikipedia_images: 'Wikipedia images',
            web: 'Web',
            images: 'Images',
            search_all: 'Search all',
            search_matches: 'Show match....',
            search_matches_plural: 'Show {{count}} matches....',
            splash_text_XXX: `
<0>TA version {{ta2Version_vocab}}</0>
<1>Terminologia Anatomica is the international standard 
of anatomical terminology published by
<1>FIPAT (Federative International Programme for Anatomical Terminology)</1>, 
a program of IFAA (the International Federation of Associations of Anatomists).
</1>
<2>For more information on TA2, including status, citation and licensing information, please see: 
<1>https://fipat.library.dal.ca/ta2/</1>.
</2> <3>TA2Viewer is a joint project between FIPAT and the 
<1>Open Anatomy Project</1>  at the Brigham and Women’s Hospital in Boston. 
Please direct questions about TA2Viewer to info@openanatomy.org.</3>`
        }
    },
    fr: {
        translation: {
            search_options: 'Options de recherche',
            use_wikidata_terms: "Utiliser les termes Wikidata",
            interface_language: "Langue de l'interface",
            browser_default: 'Navigateur par défaut',
            see_also: 'Voir également',
            english_synonyms: 'Synonymes anglais',
            latin_synonyms: 'Synonymes latins',
            primary_usage: 'Utilisation principale',
            secondary_usage: 'Utilisation secondaire',
            sex: "Le sexe",
            inconstant: 'Inconstant',
            yes: "Oui",
            related_terms: "Termes connexes",
            synonyms: "Synonymes",
            search: 'chercher',
            external_search: 'Recherche externe',
            external_search_links: 'Liens de recherche externes',
            external_search_links_body: `
Ces liens permettent des recherches rapides sur les ressources médicales et les moteurs de recherche. Les résultats ne sont ni examinés ni organisés par la FIPAT.`,
            unofficial_xrefs: 'Renvois non officiels',
            unofficial_xrefs_body: `
Les références croisées sont fournies par Wikidata. Ils sont fournis par la communauté Wikipédia et ne sont ni examinés ni organisés par la FIPAT.`,
            latin: 'Latin',
            latin_term: 'Terme Latin',
            english_term: 'Terme Anglais',
            uk_english: 'Anglais Britannique',
            us_english: 'Anglais Américain',
            term_number: 'Numéro du terme',
            children: 'Les enfants',
            notes: 'Remarques',
            grays_anatomy: "Gray's Anatomy (20e éd.)",
            wikipedia_sites: 'Sites Wikipédia',
            wikidata_translations: 'Traductions Wikidata',
            wikipedia_images: 'Images Wikipédia',
            web: 'Web',
            images: 'Images',
            search_all: 'Rechercher tout',
            search_matches: 'Afficher le match....',
            search_matches_plural: 'Afficher {{count}} correspondances....',
            splash_text: `
<0> Version TA {{ta2Version_vocab}} </0>
<1> Terminologia Anatomica est la norme internationale
de terminologie anatomique publiée par
<1> FIPAT (Programme international fédératif de terminologie anatomique) </1>,
un programme de l'IFAA (Fédération internationale des associations d'anatomistes).
</1>           
<2> Pour plus d'informations sur TA2, y compris des informations sur le statut, la citation et les licences, veuillez consulter:
<1> https://fipat.library.dal.ca/ta2/ </1>.
</2> <3> TA2Viewer est un projet conjoint entre FIPAT et le
<1> Open Anatomy Project </1> au Brigham and Women’s Hospital de Boston.
Veuillez adresser vos questions sur TA2Viewer à info@openanatomy.org. </3>`
        }
    },
    es: {
        translation: {
            search_options: 'Opciones de búsqueda',
            use_wikidata_terms: "Usar términos de Wikidata",
            interface_language: 'Lenguaje de interfaz',
            browser_default: 'Navegador predeterminado',
            see_also: 'Ver también',
            english_synonyms: 'Sinónimo en inglés',
            latin_synonyms: 'Sinónimo en latin',
            primary_usage: 'Uso principal',
            secondary_usage: 'Uso secundario',
            sex: "Género",
            inconstant: 'Inconstante',
            yes: "si",
            related_terms: "Términos relacionados",
            synonyms: "Sinónimos",
            search: 'buscar',
            external_search: 'Búsqueda externa',
            external_search_links: 'Enlaces de búsqueda externos',
            external_search_links_body: `Estos enlaces permiten búsquedas rápidas de recursos médicos y motores de búsqueda. Los resultados no son revisados ​​o seleccionados por FIPAT.`,
            unofficial_xrefs: 'Referencias cruzadas no oficiales',
            unofficial_xrefs_body: 'Las referencias cruzadas son proporcionadas por Wikidata. Son aportados por la comunidad de Wikipedia y no son revisados ​​ni comisariados por FIPAT.',
            latin: 'Latín',
            latin_term: 'Término Latino',
            english_term: 'Termino Ingles',
            uk_english: 'Inglés Británico',
            us_english: 'Inglés Americano',
            term_number: 'Término número',
            children: 'Hijos',
            notes: 'Notas',
            grays_anatomy: "Gray's Anatomy (20ª ed.)",
            wikipedia_sites: 'Sitios de Wikipedia',
            wikidata_translations: 'Traducciones de Wikidata',
            wikipedia_images: 'Imágenes de Wikipedia',
            web: 'Web',
            images: 'Imágenes',
            search_all: 'Buscar todo',
            search_matches: 'Mostrar coincidencia....',
            search_matches_plural: 'Mostrar {{count}} coincidencias....',
            splash_text: `
<0> TA versión {{ta2Version_vocab}} </0>
<1> Terminologia Anatomica es el estándar internacional
de terminología anatómica publicada por
<1> FIPAT (Programa Internacional Federativo de Terminología Anatómica) </1>,
un programa de IFAA (Federación Internacional de Asociaciones de Anatomistas).
</1>
<2> Para obtener más información sobre TA2, incluido el estado, la cita y la información de licencia, consulte:
<1> https://fipat.library.dal.ca/ta2/ </1>.
</2> <3> TA2Viewer es un proyecto conjunto entre FIPAT y el
<1> Open Anatomy Project </1> en el Hospital Brigham and Women de Boston.
Dirija sus preguntas sobre TA2Viewer a info@openanatomy.org. </3>`

        }
    },
    de: {
        translation: {
            search_options: 'Suchoptionen',
            use_wikidata_terms: "Verwenden Sie Wikidata-Begriffe",
            interface_language: 'Schnittstellensprache',
            browser_default: 'Browser-Standardeinstellung',
            see_also: 'Siehe auch',
            english_synonyms: 'Englische Synonyme',
            latin_synonyms: 'Lateinische Synonyme',
            primary_usage: 'Primäre Verwendung',
            secondary_usage: 'Sekundäre Verwendung',
            sex: "Geschlecht",
            inconstant: 'Unbeständig',
            yes: "Ja",
            related_terms: "Verwandte Begriffe",
            synonyms: "Synonyme",
            search: 'Suche',
            external_search: 'Externe Suche',
            external_search_links: 'Externe Suchlinks',
            external_search_links_body: `
Diese Links ermöglichen eine schnelle Suche nach medizinischen Ressourcen und Suchmaschinen. Die Ergebnisse werden von FIPAT nicht überprüft oder kuratiert. `, 
            unofficial_xrefs: 'Inoffizielle Querverweise',
            unofficial_xrefs_body: `
Querverweise werden von Wikidata bereitgestellt. Sie werden von der Wikipedia-Community beigesteuert und nicht von FIPAT überprüft oder kuratiert. `,
            latin: 'Latein',
            latin_term: 'Lateinischer Begriff',
            english_term: 'Englischer Begriff',
            uk_english: 'UK Englisch',
            us_english: 'US Englisch',
            term_number: 'Laufzeitnummer',
            children: 'Kinder',
            notes: 'Anmerkungen',
            grays_anatomy: "Gray's Anatomy (20. Ausgabe)",
            wikipedia_sites: 'Wikipedia-Sites',
            wikidata_translations: 'Wikidata-Übersetzungen',
            wikipedia_images: 'Wikipedia-Bilder',
            web: 'Web',
            images: 'Bilder',
            search_all: 'Suche alle',
            search_matches: 'Show-Match....',
            search_matches_plural: '{{Count}} Übereinstimmungen anzeigen ....',
            splash_text: `
<0> TA-Version {{ta2Version_vocab}} </ 0>
<1> Terminologia Anatomica ist der internationale Standard
der anatomischen Terminologie veröffentlicht von
<1> FIPAT (Föderatives Internationales Programm für Anatomische Terminologie) </ 1>,
ein Programm der IFAA (International Federation of Associations of Anatomists).
</ 1>          
<2> Weitere Informationen zu TA2, einschließlich Status-, Zitier- und Lizenzinformationen, finden Sie unter:
<1> https://fipat.library.dal.ca/ta2/ </ 1>.
</ 2> <3> TA2Viewer ist ein Gemeinschaftsprojekt von FIPAT und dem
<1> Open Anatomy Project </ 1> im Brigham and Women's Hospital in Boston.
Bitte richten Sie Fragen zu TA2Viewer an info@openanatomy.org. </ 3>`
        }
    },
    pt: {
        translation: {
            search_options: 'Opções de pesquisa',
            use_wikidata_terms: "Usar termos do Wikidata",
            interface_language: 'Interface de linguagem',
            browser_default: 'Padrão do navegador',
            see_also: 'Siehe auch',
            english_synonyms: 'Sinônimos em inglês',
            latin_synonyms: 'Sinônimos em latim',
            primary_usage: 'Uso primário',
            secondary_usage: 'Uso secundário',
            sex: "Gênero",
            inconstant: 'Inconstante',
            yes: "sim",
            related_terms: "Termos relacionados",
            synonyms: "Sinônimos",
            search: 'procurar',
            external_search: 'Pesquisa externa',
            external_search_links: 'Links de pesquisa externos',
            external_search_links_body: `
Esses links permitem pesquisas rápidas de recursos médicos e mecanismos de pesquisa. Os resultados não são revisados ​​ou curados pelo FIPAT.`,
            unofficial_xrefs: 'Referências cruzadas não oficiais',
            unofficial_xrefs_body: `
    As referências cruzadas são fornecidas pelo Wikidata. Eles são contribuídos pela comunidade Wikipedia e não são revisados ​​ou organizados pelo FIPAT.`,
            latin: 'Latim',
            latin_term: 'Termo Latino',
            english_term: 'Termo em Inglês',
            uk_english: 'UK Inglês',
            us_english: 'US Inglês',
            term_number: 'Número do termo',
            children: 'Crianças',
            notes: 'Notas',
            grays_anatomy: "Anatomia de Gray (20a ed.)",
            wikipedia_sites: 'Sites da Wikipedia',
            wikidata_translations: 'Traduções do Wikidata',
            wikipedia_images: 'Imagens da Wikipedia',
            web: 'Web',
            images: 'Imagens',
            search_all: 'Pesquisar tudo',
            search_matches: 'Mostrar correspondência ....',
            search_matches_plural: 'Mostrar {{count}} correspondências ....',
            splash_text: `
<0> Versão TA {{ta2Version_vocab}} </0>
<1> Terminologia Anatomica é o padrão internacional
terminologia anatômica publicada por
<1> FIPAT (Programa Internacional Federativo de Terminologia Anatômica)</1>,
um programa da IFAA (Federação Internacional de Associações de Anatomistas).
</1>        
<2> Para obter mais informações sobre o TA2, incluindo informações de status, citação e licenciamento, consulte:
</2> <3> TA2Viewer é um projeto conjunto entre o FIPAT e os
<1> Projeto Aberto de Anatomia </1> no Hospital Brigham and Women's, em Boston.
Envie perguntas sobre o TA2Viewer para info@openanatomy.org.</3>`
        }
    },
    zh: {
        translation: {
            search_options: '搜索选项',
            use_wikidata_terms: "使用维基数据术语",
            interface_language: '界面语言',
            browser_default: '浏览器默认',
            see_also: '也可以看看',
            english_synonyms: '英文同义词',
            latin_synonyms: '拉丁语同义词',
            primary_usage: '主要用法',
            secondary_usage: '二次使用',
            sex: "性别",
            inconstant: '不稳定的',
            yes: "是",
            related_terms: "相关条款",
            synonyms: "同义字",
            search: '搜索',
            external_search: '外部搜寻',
            external_search_links: '外部搜索链接',
            external_search_links_body: `
这些链接允许快速搜索医疗资源和搜索引擎。结果未由FIPAT审核或整理。`,
            unofficial_xrefs: '非官方的交叉引用',
            unofficial_xrefs_body: `
交叉引用由Wikidata提供。它们是由Wikipedia社区贡献的，未经FIPAT审查或策划。`,
            latin: '拉丁',
            latin_term: '拉丁语',
            english_term: '英文术语',
            uk_english: '英国英语',
            us_english: '美国英语',
            term_number: '学期编号',
            children: '小孩儿',
            notes: '笔记',
            grays_anatomy: "格雷的解剖学（第20版）",
            wikipedia_sites: '维基百科网站',
            wikidata_translations: 'Wikidata翻译',
            wikipedia_images: '维基百科图片',
            web: '网络',
            images: '图片',
            search_all: '搜索全部',
            search_matches: '显示{{count}}个匹配项 ...',
            search_matches_plural: '显示{{count}}个匹配项 ...',
            splash_text: `
<0> TA版本{{ta2Version_vocab}} </ 0>
<1> Terminallogia Anatomica是国际标准
出版的解剖学术语集
<1> FIPAT（联邦国际解剖学术语计划）</ 1>，
IFAA（国际解剖学家协会联合会）的计划。
</ 1>
<2>有关TA2的更多信息，包括状态，引用和许可信息，请参阅：
<1> https://fipat.library.dal.ca/ta2/ </ 1>。
</ 2> <3> TA2Viewer是FIPAT与
波士顿布莱根妇女医院的<1>开放解剖项目</ 1>。
请将有关TA2Viewer的问题直接发送至info@openanatomy.org。</ 3>`
        }
    },
    it: {
        translation: {
            search_options: 'opzioni di ricerca',
            use_wikidata_terms: "Usa i termini di Wikidata",
            interface_language: 'Linguaggio di interfaccia',
            browser_default: 'Browser predefinito',
            see_also: 'Guarda anche',
            english_synonyms: 'Sinonimi inglesi',
            latin_synonyms: 'Sinonimi latini',
            primary_usage: 'Utilizzo primario',
            secondary_usage: 'Uso secondario',
            sex: "Genere",
            inconstant: 'incostante',
            yes: "sì",
            related_terms: "Termini correlati",
            synonyms: "Sinonimi",
            search: 'ricerca',
            external_search: 'Ricerca esterna',
            external_search_links: 'Link di ricerca esterni',
            external_search_links_body: `
Questi collegamenti consentono ricerche rapide di risorse mediche e motori di ricerca. I risultati non sono rivisti o curati da FIPAT.`,
            unofficial_xrefs: 'Riferimenti incrociati non ufficiali',
            unofficial_xrefs_body: `
I riferimenti incrociati sono forniti da Wikidata. Sono forniti dalla community di Wikipedia e non sono recensiti o curati da FIPAT.`,
            latin: 'Latino',
            latin_term: 'Termine latino',
            english_term: 'Termine inglese',
            uk_english: 'Inglese britannico',
            us_english: 'Inglese americano',
            term_number: 'Numero di termine',
            children: 'Bambini',
            notes: 'Appunti',
            grays_anatomy: "Gray's Anatomy (20a ed.)",
            wikipedia_sites: 'Siti di Wikipedia',
            wikidata_translations: 'Traduzioni di Wikidata',
            wikipedia_images: 'Immagini di Wikipedia',
            web: 'Web',
            images: 'Immagini',
            search_all: 'Cerca tutto',
            search_matches: 'Mostra partita....',
            search_matches_plural: 'Mostra {{count}} corrispondenze....',
            splash_text: `
<0> Versione TA {{ta2Version_vocab}} </0>
<1> Terminologia Anatomica è lo standard internazionale
di terminologia anatomica pubblicato da
<1> FIPAT (Programma internazionale federativo di terminologia anatomica) </1>,
un programma dell'IFAA (Federazione internazionale delle associazioni di anatomia).
</1>

<2> Per ulteriori informazioni su TA2, tra cui informazioni sullo stato, la citazione e le licenze, consultare:
<1> https://fipat.library.dal.ca/ta2/ </1>.
</2> <3> TA2Viewer è un progetto congiunto tra FIPAT e
<1> Open Anatomy Project </1> presso il Brigham and Women’s Hospital di Boston.
Si prega di inviare domande su TA2Viewer a info@openanatomy.org. </3>`
        },
        af: {
            translation: {
                search_options: 'Soekopsies',
                use_wikidata_terms: "Gebruik Wikidata-terme",
                interface_language: 'Interface taal',
                browser_default: 'Blaaier verstek',
                see_also: 'Sien ook',
                english_synonyms: 'Engelse sinonieme',
                latin_synonyms: 'Latynse sinonieme',
                primary_usage: 'Primêre gebruik',
                secondary_usage: 'Sekondêre gebruik',
                sex: "geslag",
                inconstant: 'Onstandvastig',
                yes: "ja",
                related_terms: "Verwante terme",
                synonyms: "Sinonieme",
                search: 'Soek',
                external_search: 'Eksterne soektog',
                external_search_links: 'Eksterne soekskakels',
                external_search_links_body: `
    Met hierdie skakels kan u mediese hulpbronne en soekenjins vinnig soek. Uitslae word nie deur FIPAT beoordeel of saamgestel nie.`,
                unofficial_xrefs: 'Nie-amptelike kruisverwysings',
                unofficial_xrefs_body: `
    Kruisverwysings word verskaf deur Wikidata. Dit word deur die Wikipedia-gemeenskap bygedra en word nie deur FIPAT hersien of saamgestel nie.`,
                latin: 'Latyns',
                latin_term: 'Latynse term',
                english_term: 'Engelse term',
                uk_english: 'Britse Engels',
                us_english: 'Amerikaanse Engels',
                term_number: 'Termyn nommer',
                children: 'Kinders',
                notes: 'Notas',
                grays_anatomy: "Gray's Anatomy (20ste ed.)",
                wikipedia_sites: 'Wikipedia-webwerwe',
                wikidata_translations: 'Wikidata-vertalings',
                wikipedia_images: 'Wikipedia-beelde',
                web: 'Web',
                images: 'Beelde',
                search_all: 'Soek alles',
                search_matches: 'Wys wedstryd....',
                search_matches_plural: 'Wys {{count}} vuurhoutjies....',
                splash_text: `
<0> TA-weergawe {{ta2Version_vocab}} </0>
<1> Terminologia Anatomica is die internasionale standaard
van anatomiese terminologie gepubliseer deur
<1> FIPAT (Federatiewe Internasionale Program vir Anatomiese Terminologie) </1>,
'n program van IFAA (die International Federation of Associations of Anatomists).
</1>            
<2> Vir meer inligting oor TA2, insluitend inligting oor status, verwysings en lisensies, sien:
<1> https://fipat.library.dal.ca/ta2/ </ 1>.
</2> <3> TA2Viewer is 'n gesamentlike projek tussen FIPAT en die
<1> Open Anatomy Project </1> in die Brigham and Women’s Hospital in Boston.
Rig vrae oor TA2Viewer na info@openanatomy.org. </3>`
            }
        }
    },
    ja: {
        translation: {
            search_options: '検索オプション',
            use_wikidata_terms: "ウィキデータの用語を使用する",
            interface_language: 'インターフェース言語',
            browser_default: 'ブラウザのデフォルト',
            see_also: 'こちらもご覧ください',
            english_synonyms: '英語の同義語',
            latin_synonyms: 'ラテン語の同義語',
            primary_usage: '主な用途',
            secondary_usage: '二次利用',
            sex: "性別",
            inconstant: '不定',
            yes: "はい",
            related_terms: "関連用語",
            synonyms: "同義語",
            search: '探す',
            external_search: '外部検索',
            external_search_links: '外部検索リンク',
            external_search_links_body: `
これらのリンクにより、医療リソースと検索エンジンの用語をすばやく検索できます。結果はFIPATによってレビューまたはキュレートされません。`,
            unofficial_xrefs: '非公式の相互参照',
            unofficial_xrefs_body: `
相互参照はウィキデータによって提供されます。これらはウィキペディアコミュニティによって提供されており、FIPATによるレビューやキュレーションは行われていません。`,
            latin: 'ラテン',
            latin_term: 'ラテン語',
            english_term: '英語用語',
            uk_english: 'イギリス英語',
            us_english: 'アメリカ英語',
            term_number: '用語番号',
            children: '子供達',
            notes: 'ノート',
            grays_anatomy: "Gray's Anatomy (20th ed.)",
            wikipedia_sites: 'Wikipedia サイト',
            wikidata_translations: 'Wikidata 翻訳',
            wikipedia_images: 'Wikipedia 画像',
            web: 'ウェブ',
            images: '画像',
            search_all: 'すべてを検索',
            search_matches: '{{count}}件の一致を表示....',
            search_matches_plural: '{{count}}件の一致を表示....',
            splash_text: `
<0> TAバージョン{{ta2Version_vocab}} </ 0>
<1>用語集アナトミカは国際標準です
解剖学用語集
<1> FIPAT（解剖学的用語の国際連合プログラム）</ 1>、
IFAA（国際解剖学協会連合）のプログラム。
</1>
<2>ステータス、引用、ライセンス情報など、TA2の詳細については、以下を参照してください。
<1> https://fipat.library.dal.ca/ta2/ </1>。
</2> <3> TA2Viewerは、FIPATと
<1> Open Anatomy Project </ 1>ボストンのブリガムアンドウィメンズ病院で。
TA2Viewerに関する質問は、info @ openanatomy.orgに送信してください。</3>`
        }
    },
    tr: {
        translation: {
            search_options: 'Arama seçenekleri',
            use_wikidata_terms: "Wikidata terimlerini kullan",
            interface_language: 'Arayüz dili',
            browser_default: 'Tarayıcı varsayılanı',
            see_also: 'Ayrıca bakınız',
            english_synonyms: 'İngilizce eşanlamlıları',
            latin_synonyms: 'Latince eş anlamlılar',
            primary_usage: 'Birincil kullanım',
            secondary_usage: 'İkincil kullanım',
            sex: "Cinsiyet",
            inconstant: 'gelgeç',
            yes: "Evet",
            related_terms: "İlgili terimler",
            synonyms: "Eş anlamlı",
            search: 'arama',
            external_search: 'Harici arama',
            external_search_links: 'Harici arama bağlantıları',
            external_search_links_body: `
Bu bağlantılar tıbbi kaynakların ve arama motorlarının hızlı dönem aramasına izin verir. Sonuçlar FIPAT tarafından gözden geçirilmez veya iyileştirilmez.`,
            unofficial_xrefs: 'Resmi olmayan çapraz referanslar',
            unofficial_xrefs_body: `
Çapraz referanslar Wikidata tarafından sağlanır. Wikipedia topluluğuna katkıda bulunurlar ve FIPAT tarafından incelenmez veya küratörlüğünü yapmazlar.`,
            latin: 'Latin',
            latin_term: 'Latince terimi',
            english_term: 'İngilizce terim',
            uk_english: 'İngiltere İngilizcesi',
            us_english: 'Amerikan ingilizcesi',
            term_number: 'Terim numarası',
            children: 'Cocuklar',
            notes: 'Notlar',
            grays_anatomy: "Gray Anatomisi (20. baskı)",
            wikipedia_sites: 'Wikipedia siteleri',
            wikidata_translations: 'Wikidata çevirileri',
            wikipedia_images: 'Wikipedia resimleri',
            web: 'Web',
            images: 'Görüntüler',
            search_all: 'Tümünü ara',
            search_matches: 'Gösteri maçı....',
            search_matches_plural: '{{Count}} eşleşmeyi göster....',
            splash_text: `
<0> TA sürümü {{ta2Version_vocab}} </0>
<1> Terminoloji Anatomica uluslararası standarttır
tarafından yayınlanan anatomik terminolojinin
<1> FIPAT (Uluslararası Anatomik Terminoloji Programı) </1>,
bir IFAA programı (Uluslararası Anatomistler Dernekleri Federasyonu).
</ 1>
<2> Durum, atıf ve lisans bilgileri de dahil olmak üzere TA2 hakkında daha fazla bilgi için lütfen bkz:
<1> https://fipat.library.dal.ca/ta2/ </ 1>.
</2> <3> TA2Viewer, FIPAT ile FIPAT arasındaki ortak bir projedir.
Boston'daki Brigham ve Kadın Hastanesinde <1> Açık Anatomi Projesi </1>.
Lütfen TA2Viewer ile ilgili sorularınızı info@openanatomy.org adresine yönlendirin. </3>`
        }
    },
    id: {
        translation: {
            search_options: 'pilihan pencarian',
            use_wikidata_terms: "Gunakan istilah Wikidata",
            interface_language: 'Bahasa antarmuka',
            browser_default: 'Default browser',
            see_also: 'Lihat juga',
            english_synonyms: 'Sinonim bahasa Inggris',
            latin_synonyms: 'Sinonim latin',
            primary_usage: 'Penggunaan primer',
            secondary_usage: 'Penggunaan sekunder',
            sex: "Jenis kelamin",
            inconstant: 'Tidak konstan',
            yes: "Iya",
            related_terms: "Istilah terkait",
            synonyms: "Sinonim",
            search: 'Cari',
            external_search: 'Pencarian eksternal',
            external_search_links: 'Tautan pencarian eksternal',
            external_search_links_body: `
Tautan ini memungkinkan pencarian sumber daya medis dan mesin pencari jangka pendek. Hasil tidak ditinjau atau dikuratori oleh FIPAT.`,
            unofficial_xrefs: 'Referensi silang tidak resmi',
            unofficial_xrefs_body: `
Referensi silang disediakan oleh Wikidata. Mereka dikontribusikan oleh komunitas Wikipedia dan tidak ditinjau atau dikuratori oleh FIPAT.`,
            latin: 'Latin',
            latin_term: 'Istilah latin',
            english_term: 'Istilah bahasa inggris',
            uk_english: 'Inggris Inggris',
            us_english: 'Bahasa Inggris AS',
            term_number: 'Nomor jangka',
            children: 'Anak-anak',
            notes: 'Catatan',
            grays_anatomy: "Gray's Anatomy (edisi ke-20)",
            wikipedia_sites: 'Situs Wikipedia',
            wikidata_translations: 'Terjemahan Wikidata',
            wikipedia_images: 'Gambar Wikipedia',
            web: 'Web',
            images: 'Gambar-gambar',
            search_all: 'Cari semua',
            search_matches: 'Tampilkan pertandingan....',
            search_matches_plural: 'Tampilkan {{count}} yang cocok....',
            splash_text: `
<0> Versi TA {{ta2Version_vocab}} </0>
<1> Terminologia Anatomica adalah standar internasional
terminologi anatomi yang diterbitkan oleh
<1> FIPAT (Program Internasional Federasi untuk Terminologi Anatomi) </1>,
sebuah program IFAA (Federasi Internasional Asosiasi Ahli Anatomi).
</1>
<2> Untuk informasi lebih lanjut tentang TA2, termasuk status, kutipan dan informasi lisensi, silakan lihat:
<1> https://fipat.library.dal.ca/ta2/ </1>.
</2> <3> TA2Viewer adalah proyek bersama antara FIPAT dan
<1> Proyek Anatomi Terbuka </1> di Rumah Sakit Brigham and Women di Boston.
Silakan ajukan pertanyaan tentang TA2Viewer ke info@openanatomy.org. </3>`
        }
    },
    no: {
        translation: {
            search_options: 'Søkealternativer',
            use_wikidata_terms: "Bruk Wikidata-vilkår",
            interface_language: 'Grensesnitt språk',
            browser_default: 'Nettleser standard',
            see_also: 'Se også',
            english_synonyms: 'Engelske synonymer',
            latin_synonyms: 'Latinsk synonymer',
            primary_usage: 'Primær bruk',
            secondary_usage: 'Sekundær bruk',
            sex: "Kjønn",
            inconstant: 'Inconstant',
            yes: "ja",
            related_terms: "Beslektede vilkår",
            synonyms: "Synonymer",
            search: 'Søk',
            external_search: 'Eksternt søk',
            external_search_links: 'Eksterne søkekoblinger',
            external_search_links_body: `
Disse koblingene tillater hurtig sikt av medisinske ressurser og søkemotorer. Resultatene blir ikke vurdert eller kuratert av FIPAT.`,
            unofficial_xrefs: 'Uoffisielle kryssreferanser',
            unofficial_xrefs_body: `
Kryssreferanser er gitt av Wikidata. De er bidratt av Wikipedia-samfunnet og blir ikke vurdert eller kuratert av FIPAT.`,
            latin: 'Latin',
            latin_term: 'Latinsk betegnelse',
            english_term: 'Engelsk begrep',
            uk_english: 'UK engelsk',
            us_english: 'Amerikansk engelsk',
            term_number: 'Term nummer',
            children: 'Barn',
            notes: 'Merknader',
            grays_anatomy: "Grey's Anatomy (20. utg.)",
            wikipedia_sites: 'Wikipedia-nettsteder',
            wikidata_translations: 'Wikidata-oversettelser',
            wikipedia_images: 'Wikipedia-bilder',
            web: 'Web',
            images: 'Bilder',
            search_all: 'Søk alle',
            search_matches: 'Vis kamp....',
            search_matches_plural: 'Vis {{count}} treff....',
            splash_text: `
<0> TA-versjon {{ta2Version_vocab}} </0>
<1> Terminologia Anatomica er den internasjonale standarden
av anatomisk terminologi utgitt av
<1> FIPAT (Federative International Program for Anatomical Terminology) </1>,
et program fra IFAA (International Federation of Associations of Anatomists).
</ 1>
<2> For mer informasjon om TA2, inkludert informasjon om status, sitering og lisenser, vennligst se:
<1> https://fipat.library.dal.ca/ta2/ </ 1>.
</2> <3> TA2Viewer er et felles prosjekt mellom FIPAT og
<1> Open Anatomy Project </1> på Brigham and Women’s Hospital i Boston.
Send spørsmål om TA2Viewer til info@openanatomy.org. </3>`
        }
    },
    sv: {
        translation: {
            search_options: 'sökmöjligheter',
            use_wikidata_terms: "Använd Wikidata-termer",
            interface_language: 'Gränssnittsspråk',
            browser_default: 'Webbläsarens standard',
            see_also: 'Se även',
            english_synonyms: 'Engelska synonymer',
            latin_synonyms: 'Latinska synonymer',
            primary_usage: 'Primär användning',
            secondary_usage: 'Sekundär användning',
            sex: "Kön",
            inconstant: 'Obeständig',
            yes: "ja",
            related_terms: "Relaterade termer",
            synonyms: "Synonymer",
            search: 'Sök',
            external_search: 'Extern sökning',
            external_search_links: 'Externa söklänkar',
            external_search_links_body: `
Dessa länkar gör det möjligt att snabbt söka medicinska resurser och sökmotorer. Resultaten granskas eller kurateras inte av FIPAT.`,
            unofficial_xrefs: 'Inofficiella korsreferenser',
            unofficial_xrefs_body: `
Korsreferenser tillhandahålls av Wikidata. De tillförs av Wikipedia-gemenskapen och granskas eller kurateras inte av FIPAT.`,
            latin: 'Latin',
            latin_term: 'Latinska termen',
            english_term: 'Engelska termen',
            uk_english: 'UK engelska',
            us_english: 'US engelska',
            term_number: 'Termnummer',
            children: 'Barn',
            notes: 'Anteckningar',
            grays_anatomy: "Grey's Anatomy (20: e upplagan)",
            wikipedia_sites: 'Wikipedia-webbplatser',
            wikidata_translations: 'Wikidata översättningar',
            wikipedia_images: 'Wikipedia-bilder',
            web: 'Web',
            images: 'Bilder',
            search_all: 'Sök alla',
            search_matches: 'Visa {{count}} matchningar....',
            search_matches_plural: 'Visa {{count}} matchningar....',
            splash_text: `
<0> TA-version {{ta2Version_vocab}} </0>
<1> Terminologia Anatomica är den internationella standarden
av anatomisk terminologi publicerad av
<1> FIPAT (Federative International Program for Anatomical Terminology) </1>
ett program av IFAA (International Federation of Associations of Anatomists).
</ 1>

<2> För mer information om TA2, inklusive information om status, citering och licens, se:
<1> https://fipat.library.dal.ca/ta2/ </ 1>.
</2> <3> TA2Viewer är ett gemensamt projekt mellan FIPAT och
<1> Open Anatomy Project </1> på Brigham and Women’s Hospital i Boston.
Vänligen rikta frågor om TA2Viewer till info@openanatomy.org. </3>`
        }
    },
    ru: {
        translation: {
            search_options: 'Параметры поиска',
            use_wikidata_terms: "Используйте термины Wikidata",
            interface_language: 'Язык интерфейса',
            browser_default: 'Браузер по умолчанию',
            see_also: 'Смотрите также',
            english_synonyms: 'Английские синонимы',
            latin_synonyms: 'Латинские синонимы',
            primary_usage: 'Основное использование',
            secondary_usage: 'Вторичное использование',
            sex: "Пол",
            inconstant: 'непостоянный',
            yes: "да",
            related_terms: "Родственные термины",
            synonyms: "Синонимы",
            search: 'поиск',
            external_search: 'Внешний поиск',
            external_search_links: 'Eвнешние поисковые ссылки',
            external_search_links_body: `
Эти ссылки позволяют осуществлять быстрый поиск медицинских ресурсов и поисковых систем. Результаты не рассматриваются и не проверяются FIPAT.`,
            unofficial_xrefs: 'Неофициальные перекрестные ссылки',
            unofficial_xrefs_body: `
Перекрестные ссылки предоставлены Wikidata. Они предоставлены сообществом Википедии и не проверяются и не контролируются FIPAT.`,
            latin: 'латынь',
            latin_term: 'Латинский термин',
            english_term: 'Английский термин',
            uk_english: 'Английский английский',
            us_english: 'Американский английский',
            term_number: 'Срок номер',
            children: 'Дети',
            notes: 'Примечания',
            grays_anatomy: "Анатомия Грея (20-е изд.)",
            wikipedia_sites: 'Википедия сайтов',
            wikidata_translations: 'Переводы Wikidata',
            wikipedia_images: 'Википедия изображения',
            web: 'Web',
            images: 'картинки',
            search_all: 'искать все',
            search_matches: 'Показать {{count}} совпадения....',
            search_matches_plural: 'Показать {{count}} совпадения....',
            splash_text: `
<0> версия TA {{ta2Version_vocab}} </0>
<1> Terminologia Anatomica - международный стандарт
анатомической терминологии, опубликованной
<1> FIPAT (Федеративная международная программа по анатомической терминологии) </1>,
программа IFAA (Международная федерация ассоциаций анатомов).
</1>
<2> Для получения дополнительной информации о TA2, включая информацию о статусе, цитировании и лицензировании, см .:
<1> https://fipat.library.dal.ca/ta2/ </1>.
</2> <3> TA2Viewer - это совместный проект FIPAT и
<1> Открытый проект анатомии </1> в Бригаме и женской больнице в Бостоне.
Пожалуйста, направляйте вопросы о TA2Viewer на info@openanatomy.org. </3>`
        }
    },
    ar: {
        translation: {
            search_options: 'خيارات البحث',
            use_wikidata_terms: "استخدم مصطلحات Wikidata",
            interface_language: 'لغة الواجهة',
            browser_default: 'المتصفح الافتراضي',
            see_also: 'أنظر أيضا',
            english_synonyms: 'المرادفات الإنجليزية',
            latin_synonyms: 'المرادفات اللاتينية',
            primary_usage: 'الاستخدام الأساسي',
            secondary_usage: 'الاستخدام الثانوي',
            sex: "جنس",
            inconstant: 'مضايق',
            yes: "نعم",
            related_terms: "المصطلحات ذات الصلة",
            synonyms: "مرادفات",
            search: 'بحث',
            external_search: 'البحث الخارجي',
            external_search_links: 'روابط البحث الخارجي',
            external_search_links_body: `
هذه الروابط تسمح بالبحث السريع عن الموارد الطبية ومحركات البحث. لا تتم مراجعة النتائج أو تنسيقها بواسطة FIPAT.`,
            unofficial_xrefs: 'المراجع التبادلية غير الرسمية',
            unofficial_xrefs_body: `
يتم توفير المراجع التبادلية بواسطة Wikidata. يتم مشاركتهم بواسطة مجتمع Wikipedia ولا تتم مراجعتها أو تنسيقها بواسطة FIPAT.`,
            latin: 'اللاتينية',
            latin_term: 'مصطلح اللاتينية',
            english_term: 'مصطلح اللغة الإنجليزية',
            uk_english: 'المملكة المتحدة الإنجليزية',
            us_english: 'لنا الانجليزية',
            term_number: 'رقم المصطلح',
            children: 'الأطفال',
            notes: 'ملاحظات',
            grays_anatomy: "تشريح غراي (الطبعة العشرون)",
            wikipedia_sites: 'مواقع ويكيبيديا',
            wikidata_translations: 'ترجمات Wikidata',
            wikipedia_images: 'صور ويكيبيديا',
            web: 'الويب',
            images: 'الصور',
            search_all: 'بحث عن',
            search_matches: 'عرض {{count}} المطابقات ....',
            search_matches_plural: 'عرض {{count}} المطابقات ....',
            splash_text: `
<0> إصدار TA {{ta2Version_vocab}} </0>
<1> Terminologia Anatomica هو المعيار الدولي
المصطلحات التشريحية التي نشرتها FIPAT ، البرنامج الفدرالي الدولي للمصطلحات التشريحية ،
برنامج IFAA ، الاتحاد الدولي لجمعيات علماء التشريح.
</1>
<2> لمزيد من المعلومات حول TA2 ، بما في ذلك معلومات الحالة والاقتباس والترخيص ، يرجى الاطلاع على:
<1> https://fipat.library.dal.ca/ta2/ </1>.
</2> <3> TA2Viewer هو مشروع مشترك بين FIPAT و
فتح مشروع التشريح في مستشفى بريجهام والنساء في بوسطن.
يرجى توجيه الأسئلة حول TA2Viewer إلى info@openanatomy.org. </3>`
        }
    },
    hi: {
        translation: {
            search_options: 'खोज विकल्प',
            use_wikidata_terms: "विकिडाटा शब्दों का प्रयोग करें",
            interface_language: 'अंतरफलक भाषा',
            browser_default: 'ब्राउज़र डिफ़ॉल्ट',
            see_also: 'यह सभी देखें',
            english_synonyms: 'अंग्रेजी पर्यायवाची',
            latin_synonyms: 'लैटिन पर्यायवाची',
            primary_usage: 'प्राथमिक उपयोग',
            secondary_usage: 'माध्यमिक उपयोग',
            sex: "लिंग",
            inconstant: 'अस्थिर',
            yes: "हाँ",
            related_terms: "संबंधित शर्तें",
            synonyms: "समानार्थक शब्द",
            search: 'खोज',
            external_search: 'बाहरी खोज',
            external_search_links: 'बाहरी खोज लिंक',
            external_search_links_body: `
ये लिंक चिकित्सा संसाधनों और खोज इंजन की त्वरित खोज की अनुमति देते हैं। परिणामों की समीक्षा या FIPAT द्वारा क्यूरेट नहीं की जाती है।`,
            unofficial_xrefs: 'अनौपचारिक क्रॉस-संदर्भ',
            unofficial_xrefs_body: `
क्रॉस-संदर्भ विकीडाटा द्वारा प्रदान किए जाते हैं। वे विकिपीडिया समुदाय द्वारा योगदान कर रहे हैं और FIPAT द्वारा समीक्षा या क्यूरेट नहीं किए गए हैं।`,
            latin: 'लैटिन',
            latin_term: 'लैटिन शब्द',
            english_term: 'अंग्रेजी शब्द',
            uk_english: 'यूके अंग्रेजी',
            us_english: 'अमेरिका की अंग्रेजी',
            term_number: 'पद संख्या',
            children: 'बच्चे',
            notes: 'टिप्पणियाँ',
            grays_anatomy: "ग्रे का एनाटॉमी (20 वां संस्करण)",
            wikipedia_sites: 'विकिपीडिया साइटों',
            wikidata_translations: 'विकिडाटा अनुवाद',
            wikipedia_images: 'विकिपीडिया चित्र',
            web: 'वेब',
            images: 'इमेजिस',
            search_all: 'सभी खोजें',
            search_matches: '{{count}} मैच दिखाओ…।',
            search_matches_plural: '{{count}} मैच दिखाओ…।',
            splash_text: `
<0> टीए संस्करण {{ta2Version_vocab}} </0>
<1> टर्मिनोलोनिया एनाटोमिका अंतर्राष्ट्रीय मानक है
द्वारा प्रकाशित संरचनात्मक शब्दावली का
<1> FIPAT (एनाटोमिकल शब्दावली के लिए फेडेरेटिव इंटरनेशनल प्रोग्राम) </1>,
IFAA (एनाटोमिस्ट्स के अंतर्राष्ट्रीय संघों का एक कार्यक्रम)।
</1>
<2> TA2 के बारे में अधिक जानकारी के लिए, स्थिति, प्रशस्ति पत्र और लाइसेंस जानकारी सहित, कृपया देखें:
<1> https://fipat.library.dal.ca/ta2/ </1>।
</2> <3> TA2Viewer FIPAT और a के बीच एक संयुक्त परियोजना है
बोस्टन में ब्रिघम और महिला अस्पताल में <1> ओपन एनाटॉमी प्रोजेक्ट </1>।
TA2Viewer के बारे में सीधे जानकारी के लिए कृपया info@openanatomy.org पर जाएं। </3>`
        }
    },
    ne: {
        translation: {
            search_options: 'खोज विकल्पहरू',
            use_wikidata_terms: "विकिडाटा सर्तहरू प्रयोग गर्नुहोस्",
            interface_language: 'इन्टरफेस भाषा',
            browser_default: 'ब्राउजर पूर्वनिर्धारित',
            see_also: 'पनि हेर्नुहोस्',
            english_synonyms: 'अंग्रेजी पर्यायवाची शब्द',
            latin_synonyms: 'ल्याटिन पर्यायवाची शब्द',
            primary_usage: 'प्राथमिक प्रयोग',
            secondary_usage: 'दोस्रो प्रयोग',
            sex: "लि .्ग",
            inconstant: 'Inconstant',
            yes: "हो",
            related_terms: "सम्बन्धित सर्तहरू",
            synonyms: "समानार्थी शब्द",
            search: 'खोजी गर्नुहोस्',
            external_search: 'बाह्य खोजी',
            external_search_links: 'बाह्य खोज लिंकहरू',
            external_search_links_body: `
यी लिंकहरूले चिकित्सा श्रोतहरू र खोज ईन्जिनहरूको द्रुत अवधि खोजीलाई अनुमति दिन्छ। FIPAT द्वारा नतिजा समीक्षा वा क्युरेट गरिएको छैन।`,
            unofficial_xrefs: 'अनौपचारिक क्रस-सन्दर्भहरू',
            unofficial_xrefs_body: `
क्रस-सन्दर्भ विकीडाटा द्वारा प्रदान गरिएको हो। ती विकिपीडिया समुदाय द्वारा योगदान गरीरहेका छन् र FIPAT द्वारा समीक्षा वा क्युरेट गरिएको छैन।`,
            latin: 'ल्याटिन',
            latin_term: 'ल्याटिन शब्द',
            english_term: 'अंग्रेजी शब्द',
            uk_english: 'बेलायत अंग्रेजी',
            us_english: 'अमेरिकी अंग्रेजी',
            term_number: 'अवधि संख्या',
            children: 'बच्चाहरु',
            notes: 'नोटहरू',
            grays_anatomy: "Gray's Anatomy (20th ed.)",
            wikipedia_sites: 'विकिपेडिया साइटहरु',
            wikidata_translations: 'विकीडाटा अनुवाद',
            wikipedia_images: 'विकिपेडिया छविहरू',
            web: 'वेब',
            images: 'छविहरू',
            search_all: 'सबै खोज्नुहोस्',
            search_matches: '{{count}} मेलहरू देखाउनुहोस्....',
            search_matches_plural: '{{count}} मेलहरू देखाउनुहोस्....',
        }
    },
    hu: {
        translation: {
            search_options: 'Keresési lehetőségek',
            use_wikidata_terms: "Használja a Wikidata kifejezéseket",
            interface_language: 'Interfész nyelve',
            browser_default: 'Alapértelmezett böngésző',
            see_also: 'Lásd még',
            english_synonyms: 'Angol szinonimák',
            latin_synonyms: 'Latin szinonimák',
            primary_usage: 'Elsődleges használat',
            secondary_usage: 'Másodlagos használat',
            sex: "nem",
            inconstant: 'állhatatlan',
            yes: "Igen",
            related_terms: "Kapcsolódó kifejezések",
            synonyms: "Szinonimák",
            search: 'keresés',
            external_search: 'Külső keresés',
            external_search_links: 'Külső keresési linkek',
            external_search_links_body: `
Ezek a linkek lehetővé teszik az orvosi források és keresőmotorok gyors távkeresését. Az eredményeket a FIPAT nem vizsgálja felül és nem kezeli.`,
            unofficial_xrefs: 'Nem hivatalos kereszthivatkozások',
            unofficial_xrefs_body: `
A kereszthivatkozásokat a Wikidata nyújtja. Ezeket a Wikipedia közösség közreműködésével készítik, és a FIPAT nem vizsgálja felül és nem készíti őket.`,
            latin: 'latin',
            latin_term: 'Latin kifejezés',
            english_term: 'Angol kifejezés',
            uk_english: 'Angol angol',
            us_english: 'Amerikai angol',
            term_number: 'Termék száma',
            children: 'Gyermekek',
            notes: 'Megjegyzések',
            grays_anatomy: "Gray's Anatomy (20th ed.)",
            wikipedia_sites: 'Wikipedia oldalak',
            wikidata_translations: 'Wikidata fordítások',
            wikipedia_images: 'Wikipedia képek',
            web: 'Háló',
            images: 'Képek',
            search_all: 'Összes keresése',
            search_matches: 'Mutasd a (z) {{count}} mérkőzéseket....',
            search_matches_plural: 'Mutasd a (z) {{count}} mérkőzéseket....',
            splash_text: `
<0> TA verzió: {{ta2Version_vocab}} </0>
<1> A Terminologia Anatomica a nemzetközi szabvány
az anatómiai terminológia kiadója
<1> FIPAT (anatómiai terminológia szövetségi nemzetközi programja) </1>,
az IFAA (az Anatómisták Szövetsége Nemzetközi Szövetsége) programja.
</1>
<2> A TA2-vel kapcsolatos további információkért, beleértve az állapot-, hivatkozási és licencinformációkat, kérjük, olvassa el a következő oldalt:
<1> https://fipat.library.dal.ca/ta2/ </1>.
</2> <3> A TA2Viewer a FIPAT és a
<1> Nyílt anatómiai projekt </1> a bostoni Brigham és Női Kórházban.
Kérjük, kérdezze meg a TA2Viewer kérdéseit az info@openanatomy.org címre. </3>`
        }
    },
};

export { languages, languageOrder, translations };
