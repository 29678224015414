import React, { useState, useRef, useEffect } from 'react';
import Autocomplete from 'antd/lib/auto-complete';
import useDebounce from 'react-use/lib/useDebounce';
import { useTranslation } from "react-i18next";

const Option = Autocomplete.Option;
const MaxMatches = 250;
const DebounceDelayMS = 300;

export function RecordSearchView({ recordTree, 
                                   termLanguage, 
                                   onSelect, 
                                   className, 
                                   recordSearch,
                                   wikidataSynonymsEnabled, 
                                   placeholder }) {
    const [matchingNodes, setMatchingNodes] = useState([]);
    const [searchString, setSearchString] = useState('');
    const [renderUnlimited, setRenderUnlimited] = useState(false);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [wdSynonymData, setWdSynonymData] = useState(null);
    const {t} = useTranslation();

    useEffect(() => {
        // conditional must be called in the effect
        recordSearch.index();
        if (wikidataSynonymsEnabled) {
            if(wdSynonymData) {
                recordSearch.addWikidataSynonyms(wdSynonymData);
            }
            else {
                import("./data/wikidata-synonyms.json").then(data => {
                    recordSearch.addWikidataSynonyms(data.default);
                    setWdSynonymData(data.default);
                });
            }
        }
    }, [wikidataSynonymsEnabled, wdSynonymData, recordSearch]);

    const autocompleteRef = useRef();
    const localOnSelect = (v, o) => {
        if (v === 'TooMany') {
            setRenderUnlimited(true);
            setSearchString(searchString);
            return;
        }
        if (onSelect) {
            onSelect([o.props.id]);
        }
        setRenderUnlimited(false);
        setSearchString('');
        setMatchingNodes([]);
        setDropdownOpen(false);
        autocompleteRef.current.blur();
    };

    const onSearch = (s) => {
        setRenderUnlimited(false);
        setSearchString(s);
        setDropdownOpen(true);
    }

    useDebounce(() => {
        const mm = recordSearch.match(searchString, termLanguage);
        setMatchingNodes(mm);
    },
        DebounceDelayMS,
        [searchString]);

    let children, tooMany;
    if (!renderUnlimited && matchingNodes.length > MaxMatches) {
        children = [];
        const matchStr = t('search_matches', {count: matchingNodes.length});
        tooMany = [<Option key="TooMany">{matchStr}</Option>];
    }
    else {
        tooMany = [];
        children = matchingNodes.map(m => {
            const primaryTerm = recordTree.getTerm(m.id, termLanguage);
            const dphrase = recordTree.getDisambiguatingPhrase(m.id, termLanguage);
            const matchingTerm = m.term;
            const dmatching = dphrase ? `${matchingTerm} (${dphrase})` : matchingTerm;
            const printTerm = primaryTerm === matchingTerm ?
                dmatching : `${dmatching} [${primaryTerm}]`;
            return <Option value={m.id} id={m.id} key={m.id}>{printTerm}</Option>;
        });
    }
    return (<div className={className}>
        <Autocomplete
            showSearch
            allowClear
            ref={autocompleteRef}
            optionLabelProp="value"
            value={searchString}
            onSelect={localOnSelect}
            onBlur={() => setDropdownOpen(false)}
            onSearch={onSearch}
            open={dropdownOpen}
            placeholder={placeholder}>
            {children.concat(tooMany)}
        </Autocomplete>

    </div>);
}
