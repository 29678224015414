import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';

import TA2Data from './data/terms.json';
import TA2NoteData from './data/notes.json';

import { RecordSearch } from './ta2search';
import { RecordTree } from './ta2tree';
import i18nInit from './i18n';
import * as ReactGA from 'react-ga-donottrack';

import * as serviceWorker from './serviceWorker';

import * as FastClick from 'fastclick';

ReactGA.initialize('UA-125634700-2');
ReactGA.pageview(window.location.pathname + window.location.search);

if ('addEventListener' in document) {
    document.addEventListener('DOMContentLoaded', function () {
        FastClick.attach(document.body);
    }, false);
}

const recordTree = new RecordTree(TA2Data, TA2NoteData);
const recordSearch = new RecordSearch(recordTree);

i18nInit().then(() => {
    ReactDOM.render(
        <App
            className="record-viewer"
            recordTree={recordTree}
            recordSearch={recordSearch}
        />
        , document.getElementById('root'));
});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
