import React, { useState, Suspense, useEffect } from 'react';
import Button from 'antd/lib/button';
import Collapse from 'antd/lib/collapse';
import Popover from 'antd/lib/popover';

import { isArray } from 'lodash';
import LogoSplash from './LogoSplash';
import useSWR from 'swr';
import _ from 'lodash';
import { wikidataTA2Fetch, wikipediaImageFetch, getSitelinkUrl, getWikidataUrl, getGraysAnatomyInfo } from './wikiquery';
import wikidataFields from './wikidataFields';
import Spin from 'antd/lib/spin';
import { DetailGallery, DetailLightbox } from './LightboxPhoto';
import Icon from 'antd/lib/icon';
import { useTranslation } from "react-i18next";

const Panel = Collapse.Panel;
const WikidataRefreshInterval = 60 * 60 * 1000; // 60 minutes
const BulletSeparator = ' \u2022 ';

function getSexSymbol(info) {
    switch (info.sex) {
        case 'M':
            return '\u2642';
        case 'F':
            return '\u2640';
        default:
            return '';
    }
}

export function RecordDetailView(props) {
    const { className, selectedIds, recordTree, language, onSelect } = props;
    const childrenState = useState('');
    const notesState = useState('');
    const {t} = useTranslation();

    if (selectedIds.length !== 1) {
        return <LogoSplash />
    }

    const id = selectedIds[0];
    const info = recordTree.lookup(id);
    const dphrase = recordTree.getDisambiguatingPhrase(id, language);
    return (
        <div className={className}>
            <h2>{recordTree.getTerm(id, language)}</h2>
            {dphrase ? <div className="disambiguation" key="disambig">({dphrase})</div> : null}

            {breadcrumbs(info, recordTree, language, onSelect)}

            <div style={{ height: "100%" }}>
                <table key="normative">
                    <tbody key="mm">
                        {[

                            termField(info, t),
                            detailField(info, t('term_number'), 'id'),
                            info.sex !== '' ? detailField(info, t('sex'), getSexSymbol) : null,
                            info.inconstant ? detailField(info, t('inconstant'), () => t('yes')) : null,
                            detailField(null, t('latin_synonyms'), info.synonyms.la, 'initial-cap'),
                            detailField(null, t('english_synonyms'), info.synonyms.en, 'initial-cap'),
                            detailField(info, t('related_terms'), 'related_terms', 'initial-cap'),
                            info.see_also ? detailField(info, t('see_also'), 'see_also') : null,
                            wrapField(t('primary_usage'),
                                getUsage(info, 'primary_usage', recordTree, language, onSelect)),
                            wrapField(t('secondary_usage'),
                                getUsage(info, 'secondary_usage', recordTree, language, onSelect)),
                            wrapColumnSpanPanel(`${t('children')} (${recordTree.getChildrenIds(id).length} / ${recordTree.getDescendentCount(id)})`,
                                listOfChildren(id, recordTree, language, onSelect),
                                ...childrenState),
                                wrapColumnSpanPanel(t('notes'), getNotesText(recordTree, id), ...notesState)
                        ]}

                    </tbody>
                </table>
                <hr className="separator-line" />
                <DetailSearchView key="search" {...props} />
                <Suspense fallback={<Spin size="large" />}>
                    <DetailExtrasView key="extras" {...props} />
                </Suspense>

            </div>
        </div>

    );
}

function getTotalLength(d) {
    if (!d) {
        return 0;
    }
    const count = Object.values(d).reduce((acc, cur) => acc + cur[1].length, 0);
    return count;
}

function radiopaediaSearch(id, tree, t) {
    const enUSTerm = tree.getTerm(id, 'en_US');
    const searchString = encodeURIComponent(enUSTerm).replace('%20', '+');
    const url = 'https://radiopaedia.org/search?utf8=%E2%9C%93&scope=all&q=' + searchString;
    return <a href={url} key={url} target="_blank" rel='noopener noreferrer'>{t('search_all')}</a>;
}

function googleSearch(id, tree, t) {
    const laTerm = tree.getTerm(id, 'la');
    const enUSTerm = tree.getTerm(id, 'en_US');
    const terms = [laTerm];
    if (enUSTerm !== laTerm) {
        terms.push(enUSTerm);
    }
    const enGBTerm = tree.getTerm(id, 'en_GB');
    if (enGBTerm !== enUSTerm) {
        terms.push(enGBTerm);
    }
    terms.push('anatomy');
    const searchString = encodeURIComponent(terms.join(' '));
    const generalUrl = 'https://www.google.com/search?q=' + searchString;
    const imageUrl = generalUrl + '&tbm=isch';

    const frags = [];
    frags.push(<a href={generalUrl} key={generalUrl} target="_blank" rel='noopener noreferrer'>{t('web')}</a>);
    frags.push(<a href={imageUrl} key={imageUrl} target="_blank" rel='noopener noreferrer'>{t('images')}</a>);
    return joinFragments(frags, BulletSeparator);
}

function pubmedSearch(id, tree, t) {
    const enUSTerm = tree.getTerm(id, 'en_US');
    const terms = [enUSTerm];
    const query = terms.map((x) => `("${x}")`).join(' OR ');
    const url = 'https://www.ncbi.nlm.nih.gov/pubmed?term=' + encodeURIComponent(query);
    return <a href={url} key={url} target="_blank" rel='noopener noreferrer'>{t('search_all')}</a>;
}

function DetailSearchView(props) {
    const { selectedIds, recordTree } = props;
    const id = selectedIds[0];
    const {t} = useTranslation();


    const fields = [];
    fields.push(wrapField('Google', googleSearch(id, recordTree, t)));
    fields.push(wrapField('PubMed', pubmedSearch(id, recordTree, t)));
    fields.push(wrapField('Radiopaedia', radiopaediaSearch(id, recordTree, t)));
    
    return [<div key="search-header"
        className="record-detail-header">{t('external_search')}
        <HelpPopup title={<b>{t('external_search_links')}</b>} 
                    content={<div>{t('external_search_links_body')}</div>} /></div>,
    <table key="search-table">
        <tbody>
            {fields}
        </tbody>
    </table>
    ]
}
function DetailExtrasView(props) {

    const { selectedIds } = props;

    const id = selectedIds[0];
    const {t} = useTranslation();

    const { data: wikidata } = useSWR(id, wikidataTA2Fetch, {
        revalidateOnFocus: false,
        suspense: true,
        dedupingInterval: WikidataRefreshInterval
    });
    const { data: wikipediaImageData } = useSWR(() => [wikidata.merged.sitelinks.enwiki],
        articles => wikipediaImageFetch(articles, 192, 192),
        {
            revalidateOnFocus: false,
            dedupingInterval: WikidataRefreshInterval
        });

    let wikidataLanguages = [];
    let wikipediaSites = [];
    let graysAnatomyInfo = [];
    let wikidataClaims = [];
    let wikipediaEn = null;
    let wikidataEntities = null;
    if (wikidata) {
	const filteredWikipediaSites = _.filter(_.entries(wikidata.merged.sitelinks),
						([key,val])  => !key.includes('quote'));
        wikipediaSites = _.orderBy(filteredWikipediaSites, 0);
        wikidataClaims = wikidata.merged.claims;
        wikidataLanguages = _.orderBy(_.entries(wikidata.merged.labels), 0);
        graysAnatomyInfo = getGraysAnatomyInfo(wikidata.full);
        wikipediaEn = wikidata.merged.sitelinks.enwiki;
        wikidataEntities =  wikidata.merged.entityIds;
    }

    const fields = [];
    const collapseFields = [];
    for (const field of wikidataFields) {
        fields.push(formatWikidataField(field, wikidataClaims));
    }
    {
        const header = t('grays_anatomy');
        const value = formatGraysAnatomy(graysAnatomyInfo);
        fields.push(wrapField(header, value));
    }
    {
        const header = `${t('wikipedia_sites')} (${getTotalLength(wikipediaSites)})`;
        const value = wikipediaSitesView(wikipediaSites);
        fields.push(wrapField("Wikipedia", mainWikipediaView(wikipediaEn)));
        fields.push(wrapField('Wikidata', wikidataView(wikidataEntities)));
        collapseFields.push([header, value, ...useState('')]);
    }
    {
        const header = `${t('wikidata_translations')} (${getTotalLength(wikidataLanguages)})`;
        const value = wikidataLanguagesView(wikidataLanguages);
        const [c, setC] = useState('');
        collapseFields.push([header, value, c, setC]);
    }
    {
        const header = wikipediaImageData ? `${t('wikipedia_images')} (${wikipediaImageData.length})` :
            'Wikipedia images';

        const value = <WikipediaGalleryView imageInfo={wikipediaImageData} />
        const [c, setC] = useState('');

        if (wikipediaImageData && wikipediaImageData.length > 0) {
            collapseFields.push([header, value, c, setC]);
        }
    } 

    return wikidata ? [<div key="extras-header"
        className="record-detail-header">{t('unofficial_xrefs')} 
            <HelpPopup title={<b>{t('unofficial_xrefs')}</b>} 
                    content={<div>{t('unofficial_xrefs_body')}</div>} />
            </div>,
    <table key="extras">
        <tbody key="mm">
            {fields}
        </tbody>
    </table>,
    collapseFields.map(([header, value, c, setC]) => 
        value ? 
        <Collapse bordered={false} key={header} activeKey={c} onChange={setC}>
            <Panel header={header}>
                {value}
            </Panel>
            </Collapse> : null)
    ] : null;
}

function WikipediaGalleryView({ imageInfo }) {
    const [lightboxOpen, setLightboxOpen] = useState(false);
    const [currentImage, setCurrentImage] = useState(0);

    const openLightbox = (ev, evInfo) => {
        setCurrentImage(evInfo.index);
        setLightboxOpen(true);
    }

    const closeLightbox = () => {
        setLightboxOpen(false);
    }

    // if selected term changes, set the image number back to 0
    useEffect(() => {
        setCurrentImage(0);
    }, [imageInfo]);

    // this has to be after all the hooks, since hooks can't be conditional
    if (!imageInfo) {
        return null;
    }
    return <>
        <DetailGallery key="gallery"
            className="carousel"
            isOpen={lightboxOpen}
            onClose={closeLightbox}
            onClick={openLightbox}
            imageInfo={imageInfo} />
        {lightboxOpen ? <DetailLightbox
            currentImage={currentImage}
            setCurrentImage={setCurrentImage}
            onClose={closeLightbox}
            imageInfo={imageInfo} /> : null}
    </>
}

function wikidataLanguagesView(langs) {
    if (!langs || langs.length === 0) {
        return null;
    }
    return <table key="wikidataLanguges">
        <tbody>
            {langs.map(([lang, val]) => {
                return <tr key={lang}>
                    <td className="detail-subheader-key">{lang}:  </td>
                    <td>{joinFragments(val, BulletSeparator)}</td>
                </tr>
            })}
        </tbody>
    </table>;
}

function wikipediaSitesView(sites) {
    if (!sites || sites.length === 0) {
        return null;
    }
    return <table key="wikipediaSites">
        <tbody>
            {sites.map(([site, val]) => {
                try {
                    getSitelinkUrl(site, val[0]);
                }
                catch (err) {
                    // unknown language for wikidata-sdk
                    return null;
                }
                const frags = val.sort().map((v, i) => {
                    const href = getSitelinkUrl(site, v);
                    return <span key={i}><a href={href}
                        key={href}
                        target='_blank'
                        rel='noopener noreferrer'>{v}</a></span>;
                });

                return <tr key={site}>
                    <td className="detail-subheader-key">{site.replace('wiki', '')}:  </td>
                    <td>{joinFragments(frags, BulletSeparator)}</td>
                </tr>
            })}
        </tbody>
    </table>;
}

function mainWikipediaView(en) {
    if (!en || en.length === 0) {
        return null;
    }
    const frags = en.sort().map((v, i) => {
        return <span key={i}><a href={getSitelinkUrl('enwiki', v)}
            target='_blank'
            rel='noopener noreferrer'>{v}</a></span>;
    });

    return joinFragments(frags, BulletSeparator);
}

function wikidataView(ids) {
    if(!ids || ids.length === 0){
        return null;
    }
    const frags = ids.map((v) => {
        return <span key={v}><a href={getWikidataUrl(v)}
            target='_blank'
            rel='noopener noreferrer'>{v}</a></span>;
    });

    return joinFragments(frags, BulletSeparator);
}

function formatGraysAnatomy(pageInfo) {
    if (!pageInfo || pageInfo.length === 0) {
        return null;
    }

    // elminate duplicate pages
    const pageIdx = {};
    for (const p of pageInfo) {
        pageIdx[p.page] = p;
    }

    const pagef = Object.values(pageIdx).map((p, i) => {
        const altUrl = `https://bartleby.com/107/pages/page${encodeURIComponent(p.page)}.html`;
        return <span key={p}>
            {p.url ?
                <a href={p.url} target="_blank" rel='noopener noreferrer'>{p.page}</a> :
                <a href={altUrl} target="_blank" rel='noopener noreferrer'>{p.page}</a>
            }
        </span>;
    });
    return joinFragments(pagef, BulletSeparator, 'page ', 'pages ');
}

function getUsage(info, field, tree, lang, onSelect) {
    const usageId = info[field];
    if (!usageId) {
        return null;
    }
    const dterm = tree.getDisambiguatedTerm(usageId, lang);

    const res = <Button type="link" className="link-button" onClick={(x) => onSelect([usageId])}>
        <div className="initial-cap">
            {dterm}
        </div>
    </Button>;
    return res;
}

function HelpPopup({title, content}) {
    return <Popover title={<span><Icon type="question-circle"/>&nbsp;{title}</span>} content={content} trigger="focus">
        <Button type="link"><Icon type="question-circle"/></Button>


        </Popover>
}

function getNotesText(tree, id) {
    return tree.getNotesForTerm(id).map(n => `${n.note_number}. ${n.note_text}`);

}

function termField(info, t) {
    if (info.term.en_US) {
        return [
            detailField(null, t('latin_term'), info.term.la, 'initial-cap'),
            detailField(null, t('uk_english'), info.term.en_GB, 'initial-cap'),
            detailField(null, t('us_english'), info.term.en_US, 'initial-cap')
        ];
    }
    else {
        return [
            detailField(null, t('latin_term'), info.term.la, 'initial-cap'),
            detailField(null, t('english_term'), info.term.en, 'initial-cap'),
        ];
    }
}

function joinFragments(fragments, joiner, prefix, pluralPrefix) {
    if (!fragments || fragments.length === 0) {
        return null;
    }
    const i = fragments.map((f, i) =>
        <span key={i}>{[i ? <span key={"join_" + i}>{joiner}</span> : null, f]}</span>);
    if (pluralPrefix && fragments.length > 1) {
        return [pluralPrefix, i];
    }
    if (prefix) {
        return [prefix, i];
    }
    return i;
}


function detailField(info, header, field, valueClass) {
    if (!valueClass) {
        valueClass = '';
    }
    let val;

    if (info) {
        val = typeof field === 'function' ? field(info) : info[field];
    } else {
        val = field;
    }

    if (val === undefined ||
        val === null ||
        val === false ||
        val === '' ||
        (isArray(val) && val.length === 0)) {
        return null;
    }

    if (isArray(val)) {
        return (<tr key={header}>
            <th align="left">{header}</th>
            <td>{joinFragments(
                val.map(v => <span key={v} className={valueClass}>{v}</span>), ', ')
            }</td></tr>
        );
    }
    return (<tr key={header}>
        <th align="left">{header}</th><td><span className={valueClass}>{val}</span></td></tr>
    );
}

function formatWikidataField(fieldInfo, claims) {
    if (!claims || claims.length === 0 || !claims.hasOwnProperty(fieldInfo.property)) {
        return null;
    }
    const c = claims[fieldInfo.property].sort();
    if (c.length === 0) {
        return null;
    }
    const cfl = c.map((cv) => {
        return <a href={fieldInfo.url(cv)} key={fieldInfo.url(cv)} target="_blank" rel='noopener noreferrer'>{cv}</a>
    });
    return wrapField(fieldInfo.label, joinFragments(cfl, BulletSeparator));
}

function wrapField(header, render) {
    if (!render) {
        return;
    }
    return (<tr key={header}>
        <th>{header}</th><td>{render}</td></tr>
    );
}

// Be careful, this has its own state. It can't be rendered conditionally, otherwise
// the number of hooks per render won't be the same.
function wrapColumnSpanPanel(header, render, c, setC) {
    if (!render || render.length === 0) {
        return null;
    }
    return [
        <tr key={header + "2"}>
            <td colSpan="2">
                <Collapse bordered={false} activeKey={c} onChange={setC}>
                    <Panel header={header}>
                        {render}
                    </Panel>
                </Collapse>
            </td>
        </tr>
    ];
}

function breadcrumbs(info, tree, lang, onSelect) {
    const ancestors = info.hierarchy;
    const renderItems = [];
    for (var i = 0; i < ancestors.length; i++) {
        const a = ancestors[i];
        // render this way, rather than using CSS, so that the user can
        // select the entire path line
        renderItems.push(
            <Button type="link" size="small" className="button" key={i} onClick={(x) => onSelect([a])}>
                <span>
                    <span className="separator">/</span>
                    <span className="initial-cap link-like">{tree.getTerm(a, lang)}</span>
                </span>
            </Button>
        );
    }
    return (
        <div className="record-detail-breadcrumbs">
            {renderItems}
        </div>
    );
}

function listOfChildren(id, tree, lang, onSelect) {
    const childrenIds = tree.getChildrenIds(id);
    if (!childrenIds || childrenIds.length === 0) {
        return [];
    }
    return (<ul className="children">
        {childrenIds.map(a =>
            <li key={a} className="record-detail-indent">
                <Button type="link" size="small" className="button" onClick={(x) => { onSelect([a]) }}>
                    <span className="initial-cap">{tree.getTerm(a, lang)}</span>
                </Button>
            </li>)}
    </ul>
    );
}
