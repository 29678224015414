import { groupBy, values, find } from 'lodash';
import { PrefixSearch } from './prefix-search';

function promotePrimaryTerm(mnodes, lang) {
    let matchingNodes = [];
    // group all matches that map to the same ID

    const grouped = groupBy(mnodes, n => n.id);

    for (const mv of values(grouped)) {
        const v = find(mv, i => i.pref && lang === i.lang);
        matchingNodes.push(v ? v : mv[0]);
    }
    return matchingNodes;
}

function promoteExactMatches(mnodes, searchString, lang) {
    let exactMatchingLangNodes = [],
        exactMatchingNodes = [],
        otherMatchingNodes = [];
    const searchStringLower = searchString.toLowerCase();

    for (const n of mnodes) {
        if (n.term.startsWith(searchStringLower)) {
            if (lang === n.lang) {
                exactMatchingLangNodes.push(n);
            }
            else {
                exactMatchingNodes.push(n);
            }
        }
        else {
            otherMatchingNodes.push(n);
        }
    }
    return exactMatchingLangNodes.concat(exactMatchingNodes, otherMatchingNodes);
}

export class RecordSearch {
    constructor(ta) {
        this.ta = ta;
        this.index();
    }

    index() {
        const ta = this.ta;
        this.search = new PrefixSearch(ta);
        for (const id of ta.getTermIds()) {

            this.search.indexNode(id, ta.getTerm(id, 'la'), 'term', true, 'la');
            this.search.indexNode(id, ta.getTerm(id, 'en_US'), 'term', true, 'en_US');
            this.search.indexNode(id, ta.getTerm(id, 'en_GB'), 'term', true, 'en_GB');
            this.search.indexNode(id, id, 'id', false, '');
            for (const i of ta.getSynonyms(id, 'la')) {
                this.search.indexNode(id, i, 'synonym', false, 'la');
            }
            for (const i of ta.getSynonyms(id, 'la')) {
                this.search.indexNode(id, i, 'synonym', false, 'la');
            }
            for (const i of ta.getSynonyms(id, 'en')) {
                this.search.indexNode(id, i, 'synonym', false, 'en');
            }
            for (const i of ta.getRelatedTerms(id)) {
                this.search.indexNode(id, i, 'related_term', false, '');
            }
        }
    }

    addWikidataSynonyms(wikidataSynonymData) {
        for (const id of this.ta.getTermIds()) {
            if (wikidataSynonymData.hasOwnProperty(id)) {
                for (const i of wikidataSynonymData[id]) {
                    this.search.indexNode(id, i, 'wd_synonym', false, '');
                }
            }
        }
    }

    match(searchString, termLang) {
        const matchingNodes = this.search.getMatches(searchString);
        const orderedMatching = promoteExactMatches(
                                    promotePrimaryTerm(matchingNodes, termLang),
                                    searchString, termLang);
        return orderedMatching;
    }
}


