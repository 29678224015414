import React from 'react';
import Select from 'antd/lib/select';
import Form from 'antd/lib/form';
import Checkbox from 'antd/lib/checkbox';
import Icon from 'antd/lib/icon';
import Modal from 'antd/lib/modal';
import { useTranslation } from "react-i18next";
import { languages, languageOrder } from './translations';
const Option = Select.Option;

export function Settings({ visible, 
                          dismiss, 
                          interfaceLanguage, 
                          setInterfaceLanguage, 
                          useBrowserLanguage, 
                          setUseBrowserLanguage, 
                          wikidataSynonymsEnabled, 
                          setWikidataSynonymsEnabled,
                           }) {
  const { t } = useTranslation();
  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
    },
  };
  return <Modal visible={visible} title={<Icon type="setting" style={{ fontSize: "18px" }} />} onCancel={dismiss} footer={[]}>

    <Form {...formItemLayout}>

      <Form.Item label={t('interface_language')}>
        <Checkbox checked={useBrowserLanguage} onChange={(e) => setUseBrowserLanguage(e.target.checked)}>{t('browser_default')}</Checkbox>
        <Select disabled={useBrowserLanguage} onChange={setInterfaceLanguage} value={interfaceLanguage}>
          {languageOrder.map(el => <Option key={el} value={el}>{`${languages[el]} (${el})`}</Option>)}
        </Select>
      </Form.Item>
      <Form.Item label={t('search_options')}>
        <Checkbox checked={wikidataSynonymsEnabled} onChange={(e) => setWikidataSynonymsEnabled(e.target.checked)}>{t('use_wikidata_terms')}</Checkbox>

      </Form.Item>
    </Form>

  </Modal>;
}
