import wiki from "wikijs";
import wdk from "wikidata-sdk";
import _ from 'lodash';
import wikipediaBlacklist from './imageBlacklist.json';

export const getSitelinkUrl = wdk.getSitelinkUrl;


export function getWikidataUrl(e) {
    return 'https://wikidata.org/wiki/' + encodeURIComponent(e);
}

export function wikidataTA2Fetch(ta2code) {
    const id = ta2code + "";
    return fetch(wdk.getReverseClaims("P7173", id))  // TA2 property
        .then(res => res.json())
        .then(res => {
            const entitiesIds = wdk.simplify.sparqlResults(res, { minimize: true });
            if (entitiesIds.length === 0) {
                return {
                    json: x => []
                };
            }

            const entityUrl = wdk.getEntities(entitiesIds);
            return fetch(entityUrl);
        })
        .then(res => res.json())
        .then(entitiesData => {
            if (!entitiesData.entities) {
                return null;
            }
            const ret = { full: entitiesData };
            ret.simple = wdk.simplify.entities(entitiesData);
            ret.merged = wikidataMergeEntities(ret.simple);
            return ret;
        })
}


export function wikidataMergeEntities(d) {
    const entityIds = Object.keys(d).map(x => 
        parseInt(x.slice(1))).sort((a, b) => a - b).map(x => 'Q' + x);
    if (entityIds.length === 0) {
        return {
            claims: [], labels: [], sitelinks: [], entityIds: [],
            counts: { claims: 0, labels: 0, sitelinks: 0 }
        };
    }
    const merged = { entityIds, labels: {}, claims: {}, sitelinks: {} };
    for (const entity of Object.values(d)) {
        for (const field of ['labels', 'sitelinks']) {
            for (const key of Object.keys(entity[field])) {
                if (!merged[field].hasOwnProperty(key)) {
                    merged[field][key] = new Set();
                }
                merged[field][key].add(entity[field][key]);
            }
        }
        for (const listField of ['claims']) {
            for (const key of Object.keys(entity[listField])) {
                if (!merged[listField].hasOwnProperty(key)) {
                    merged[listField][key] = new Set();
                }
                for (const val of entity[listField][key]) {
                    merged[listField][key].add(val);
                }
            }
        }
    }
    // convert back to lists
    merged.counts = {};
    for (const field of ['claims', 'labels', 'sitelinks']) {
        const dd = merged[field];
        merged.counts[field] = 0;
        for (const key of Object.keys(dd)) {
            dd[key] = [...dd[key]];
            merged.counts[field] += dd[key].length;
        }
    }
    return merged;
}

export function wikidataBestEntity(d) {
    const keys = Object.keys(d);
    if (keys.length === 0) {
        return null;
    }

    let bestEntity = null;
    for (const k of keys) {
        const v = d[k];
        if (!bestEntity ||
            Object.keys(v.claims).length > Object.keys(bestEntity.claims).length) {
            bestEntity = v;
        }
    }

    return bestEntity;
}

const BogusImageRe = RegExp(wikipediaBlacklist.join('|'));

const BadFileExtensions = [
    'wav',
    'ogg',
    'ogv',
    'webm',
    'mp4',
    'mov'
]

function withWikiImageinfo(imageInfo) {
    return _.filter(imageInfo, 'imageinfo');
}

function filterFileTypes(imageInfo) {
    return _.filter(imageInfo, info => {
        const ext =  info.imageinfo[0].url.split('.').pop();
        for (const badExt of BadFileExtensions){
            if (ext === badExt){
                return false;
            }
        }
        return true;
    });
}
function filterBogusImages(imageInfo) {
    return _.filter(imageInfo, info => {
        return !BogusImageRe.test(info.imageinfo[0].url);
    });
}

function filterDuplicateImages(imageInfo) {
    return _.uniqBy(imageInfo, 'imageinfo.0.url');
}

export function wikipediaImageFetch(articles, thumbwidth, thumbheight) {
    const w = wiki({
        apiUrl: "https://en.wikipedia.org/w/api.php",
        origin: "*"
    });

    function rawImages(page, api, thumbwidth, thumbheight) {
        const raw = page.raw;
        return api({
            generator: "images",
            gimlimit: "max",
            prop: "imageinfo",
            iiprop: "url|size",
            titles: raw.title,
            iiurlwidth: thumbwidth,
            iiurlheight: thumbheight
        }).then(res => {
            if (res.query) {
                return Object.keys(res.query.pages).map(id => res.query.pages[id]);
            }
            return [];
        });
    }

    return Promise.all(articles.map(article => w.page(article)
        .then(page => rawImages(page, w.api, thumbwidth, thumbheight)
            )))
        .then(_.flatten)
        .then(withWikiImageinfo)
        .then(filterFileTypes)
        .then(filterDuplicateImages)
        .then(filterBogusImages);
    }


export function getGraysAnatomyInfo(entities) {
    const ret = [];
    for (const k of Object.keys(entities.entities)) {
        const entity = entities.entities[k];
        const vals = _.chain(entity)
            .get(["claims", "P1343"])
            .map(x => ({
                entityId: entity.id,
                page: _.get(x, ["qualifiers", "P304", 0, "datavalue", "value"]),
                url: _.get(x, [
                    "references", 0, "snaks", "P854", 0, "datavalue", "value"])
            }))
            .value();
        for (const val of vals) {
            const matches = _.some(
                ret,
                x => x.url === val.url && x.page === val.page
            );
            if (!matches && val.page) {
                if (val.url) {
                    val.url = val.url.replace('http', 'https');
                }
                ret.push(val);
            }
        }
    }
    return ret;
}

