import { sortBy, has } from 'lodash';

function getId(elem) {
    return elem['id'];
}

function updateDescendentCount(elem, index) {
    if(has(elem, 'descendentCount')) {
        return elem.descendentCount;
    }

    // compute descendentCount
    if (!has(elem, 'children')) {
        elem.descendentCount = 0;
    }
    else {
        elem.descendentCount = elem.children.reduce(
            (accum, a) => accum + updateDescendentCount(index[a], index) + 1, 0);
    }
    return elem.descendentCount;
}

export class RecordTree {
    constructor(termData, noteData) {
        this.index = {};
        this.rootIds = [];
        this.noteIndex = {};
        const data = { terms: termData, notes: noteData }

        // index terms
        for (const e of data.terms) {
            e.id = e.id + '';
            e.parent = e.parent ? e.parent + '' : null;
            e.primary_usage = e.primary_usage ? e.primary_usage + '' : null;
            e.secondary_usage = e.secondary_usage ? e.secondary_usage + '' : null;
            e.disambiguation_term = e.disambiguation_term ? e.disambiguation_term + '' : null;
            e.related_terms = e.related_terms ? e.related_terms : [];
            e.note_references = e.note_references ? e.note_references : [];
            e.synonyms = e.synonyms ? e.synonyms : { la: [], en: [] };
            const id = e.id;

            this.index[id] = e;
            if (e.parent === null) {
                this.rootIds.push(id);
            }
        }
        // sort numerically
        this.rootIds = sortBy(this.rootIds, x => parseInt(x));

        // find parents
        for (const e of data.terms) {
            const parentId = e.parent;
            if (parentId === null) {
                continue;
            }
            const parentElem = this.lookup(parentId);
            if (!has(parentElem, 'children')) {
                parentElem.children = [getId(e)];
            }
            else {
                parentElem.children.push(getId(e));
            }
        }

        // sort numerically
        for (const e of data.terms) {
            if (!has(e, 'children')) {
                e.children =sortBy(e.children, x => parseInt(x));
            }
        }

        // build hierarchy
        for (const e of data.terms) {
            const hierarchy = [];
            let node = e;
            while (true) {
                const nodeId = getId(node);
                hierarchy.push(nodeId);
                if (node.parent === null) {
                    break;
                }
                node = this.index[node.parent];
            }
            e.hierarchy = hierarchy.reverse();
        }

        // count descendents
        for (const e of data.terms) {
            updateDescendentCount(e, this.index);
        }

        // notes
        for (const n of data.notes) {
            this.noteIndex[n.note_number] = n;
        }
    }

    getDescendentCount(id) {
        if(id === null) {
            return this.rootIds.reduce((acc, a) => acc + this.getDescendentCount(a) + 1, 0);
        }
        return this.index[id].descendentCount;
    }

    getChildrenIds(id) {
        if (id === null) {
            return this.rootIds;
        }

        const elem = this.index[id];
        if (!has(elem, 'children')) {
            return null;
        }
        return elem.children;
    }

    getTermIds() {
        return Object.keys(this.index);
    }

    isValidId(id) {
        return has(this.index, id);
    }

    lookup(id) {
        return this.index[id];
    }

    getNotesForTerm(id) {
        if(!this.noteIndex){
            // in case it isn't loaded yet.
            return [];
        }
        return this.index[id].note_references.map(n => this.noteIndex[n]);
    }

    getAncestorIds(id) {
        return this.index[id].hierarchy;
    }

    getTerm(id, lang) {
        const term = this.lookup(id).term;
        if (term.hasOwnProperty(lang)) {
            return term[lang];
        }
        const lang_no_country = lang.split('_')[0];
        return this.lookup(id).term[lang_no_country];
    }

    getDisambiguatingPhrase(id, lang) {
        const part = { la: 'pars', en_US: 'part of', en_GB: 'part of' };
        const o = this.lookup(id);
        const d = o.disambiguation_term ? this.getTerm(o.disambiguation_term, lang) : null;
        return d ? `${part[lang]} ${d}` : '';
    }
    getDisambiguatedTerm(id, lang) {
        const phrase = this.getDisambiguatingPhrase(id, lang);
        const term = this.getTerm(id, lang);
        return phrase ? `${term} (${phrase})` : term;
    }

    getSynonyms(id, lang) {
        const node = this.lookup(id);
        if (!node.synonyms) {
            return [];
        };
        if (node.synonyms.hasOwnProperty(lang)) {
            return node.synonyms[lang];
        }

        return [];
    }

    getRelatedTerms(id) {
        return this.lookup(id).related_terms;
    }

}

